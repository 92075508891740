/* Scroll bar */
body::-webkit-scrollbar-track,
.ant-table-body::-webkit-scrollbar-track
{
	border-radius: 0px!important;
	background-color: transparent!important;
}

body::-webkit-scrollbar,
.ant-table-body::-webkit-scrollbar
{
	width: 5px!important;
	background-color: transparent!important;


	
}

body::-webkit-scrollbar-thumb,
.ant-table-body::-webkit-scrollbar-thumb
{
	border-radius: 10px!important;
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)!important; */
	background-color:lightgray!important;

	
}
body::-webkit-scrollbar, 
.ant-table-body::-webkit-scrollbar{
    width: 5px!important;
	background-color: transparent!important;
}
.ant-table-body{
	overflow-y: auto!important;
}
.ant-table-fixed{
    margin: 8px 0px!important;	
}