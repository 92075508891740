/* Coco Gothic Light*/
@font-face {
  font-family: "Coco Gothic";
  font-weight: lighter;
  src: url("./fonts/coco_gothic/CocoGothic-Light_trial.ttf") format("opentype");
}
/* Coco Gothic Bold*/
@font-face {
  font-family: "Coco Gothic";
  font-weight: bold;
  src: url("./fonts/coco_gothic/CocoGothic-Bold_trial.ttf") format("opentype");
}

/* Coco Gothic Bold*/
@font-face {
  font-family: "Quicksand";
  font-weight: bold;
  src: url("./fonts/coco_gothic/CocoGothic-Bold_trial.ttf") format("opentype");
}

/* NoirPro */
@font-face {
  font-family: 'NoirPro';
  src: url('./fonts/noir-pro/NoirPro-Light.eot?#iefix') format('embedded-opentype'),
  url('./fonts/noir-pro/NoirPro-Light.woff') format('woff'),
  url('./fonts/noir-pro/NoirPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url('./fonts/noir-pro/NoirPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/noir-pro/NoirPro-Regular.woff') format('woff'),
  url('./fonts/noir-pro/NoirPro-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url('./fonts/noir-pro/NoirPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('./fonts/noir-pro/NoirPro-Medium.woff') format('woff'),
  url('./fonts/noir-pro/NoirPro-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url('./fonts/noir-pro/NoirPro-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/noir-pro/NoirPro-SemiBold.woff') format('woff'),
  url('./fonts/noir-pro/NoirPro-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url('./fonts/noir-pro/NoirPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/noir-pro/NoirPro-Bold.woff') format('woff'),
  url('./fonts/noir-pro/NoirPro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url('./fonts/noir-pro/NoirPro-Heavy.eot?#iefix') format('embedded-opentype'),
  url('./fonts/noir-pro/NoirPro-Heavy.woff') format('woff'),
  url('./fonts/noir-pro/NoirPro-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}


/* Poppins Normal*/
@font-face {
  font-family: "Poppins";
  font-weight: normal;
  src: url("./fonts/poppins/Poppins-Light.ttf") format("opentype");
}
@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: url("./fonts/poppins/Poppins-Medium.ttf") format("opentype");
}
@font-face {
  font-family: "Poppins";
  font-weight: "bold";
  src: url("./fonts/poppins/Poppins-Medium.ttf") format("opentype");
}